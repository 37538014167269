<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2 d-flex">
      <button class="button-primary text-white d-flex mr-1 px-[5px]">
        <span
          v-b-modal.confirmationBack
          class="k-arrow-left-2 h-100 font-bold text-10"
          style="line-height: 1.5;"
        />
      </button>
      <span class="text-10 text--dark">Invoice</span>
    </div>
    <ValidationObserver
      ref="formRules"
      v-slot="{ invalid }"
    >
      <BRow>
        <BCol md="7">
          <BRow>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Partner <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Posisi"
                  rules="required"
                >
                  <VSelect
                    v-model="idPartner"
                    :reduce="option => option"
                    :options="listPartners"
                    placeholder="Cari nama atau no partner"
                    @search="(search) => search.length > 0 ? onSearch(search) : ''"
                    @input="handleInput"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Periode <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Periode"
                  rules="required"
                >
                  <MonthlyPicker
                    v-model="periode"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    :min="minDatePicker"
                    date-format="MMM YYYY"
                    place-holder="Pilih Periode"
                    @selected="checkAvailability()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Tipe Pembayaran <span class="text-danger">*</span>
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Tipe Pembayaran"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="isEarlyPayment"
                    class="mt-50"
                    :options="paymentOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
            <BCol md="12">
              <BFormGroup
                label-cols-md="4"
                label-class="font-medium text-7 text-black"
              >
                <template #label>
                  Keterangan
                </template>
                <ValidationProvider
                  #default="{ errors }"
                  name="Keterangan"
                  rules="max:140"
                >
                  <b-form-textarea
                    v-model="notes"
                  />
                  <div class="flex">
                    <small class="text-danger w-full">{{ errors[0] }}</small>
                    <small class="self-end">{{ notes.length }}/140</small>
                  </div>
                </ValidationProvider>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCol>
        <BCol
          md="5"
          class="d-flex justify-content-end align-items-end pb-1"
        >
          <button
            v-b-modal.confirmationSubmit
            class="button-primary fw-bold px-[40px] py-[8px]"
            :disabled="invalid || checkForm() || !items.length"
          >
            Submit
          </button>
        </BCol>
      </BRow>
    </ValidationObserver>

    <BRow>
      <BCol
        md="12"
        class="d-flex flex-column py-[20px] items-end border-t border-b pb-1 mb-1"
      >
        <div
          class="d-flex justify-content-end cursor-pointer gap-10 items-center"
          @click="visible = !visible"
        >
          <span class="text-8 text-danger">{{ visible ? 'Tutup' : 'Buka' }}</span>
          <span
            v-if="visible"
            class="k-arrow-up-2 h-100 font-bold text-10 text-danger"
          />
          <span
            v-else
            class="k-arrow-down-1 h-100 font-bold text-10 text-danger"
          />
        </div>

        <BCollapse
          v-model="visible"
          class="mt-[5px]"
        >
          <div class="d-flex flex-column items-end">
            <span class="text-8 text--dark font-bold">
              Total Biaya Admin: {{ IDR(isTax ? totalAdminFee - (totalAdminFee * 0.02) : totalAdminFee) }}
              <span v-if="isTax">{{ `( ${IDR(totalAdminFee)} - ${IDR(totalAdminFee * 0.02)} )` }}</span>
            </span>
            <span class="text-8 text--dark font-bold">Total Gaji Talent: {{ IDR(totalTalentSalary) }}</span>
            <span class="text-8 text--dark font-bold">Total Biaya Lainnya: {{ IDR(totalAdditionalCost) }}</span>
            <span class="text-8 text--dark font-bold">Xendit (Layanan Admin): {{ IDR(4440) }} </span>
          </div>
        </BCollapse>

        <span
          class="text-8 font-bold"
          style="color: #34A770;"
        >Total Nominal: {{ IDR(totalNominal) }}</span>

        <BFormCheckbox
          v-model="isTax"
          class="text-8"
        >
          Kenakan Pph 23
        </BFormCheckbox>
      </BCol>
      <BCol
        md="6"
        class="d-flex justify-content-start align-items-end pb-1"
      >
        <button
          v-b-modal.addTalent
          class="button-primary fw-bold px-[20px] py-[8px]"
          :disabled="!idPartner || (listTalentFromFetch.length === listTalentexist.length) || !items"
        >
          Tambah talent
        </button>
      </BCol>
      <BCol
        md="6"
        class="d-flex justify-content-end align-items-center pb-1"
      >
        <BFormCheckbox
          v-model="hideCosts"
          class="text-8"
        >
          Sembunyikan Biaya Admin & Gaji Talent
        </BFormCheckbox>
      </BCol>
    </BRow>
    <div id="table">
      <BTable
        :items="items"
        :fields="fields"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        style="height: calc(100vh - 275px);"
        show-empty
        :small="true"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(talent_name)="data">
          <div class="d-flex flex-column">
            <span>{{ data.item.talent_name }}</span>
            <span class="text-6 text-[#828282]">{{ data.item.role_name }}</span>
          </div>
        </template>
        <template #cell(action)="data">
          <span
            class="k-close-circle h-100 font-bold text-10 text--dark"
            @click="removeTalent(data.index)"
          />
        </template>
        <template #cell(discount)="data">
          <b-form-input
            v-model.number="data.item.discount"
            placeholder="%"
          />
        </template>
        <template #cell(talent_salary)="data">
          <ValidationProvider
            #default="{ errors }"
            name="Gaji Talent"
            rules="required|integer"
          >
            <money
              v-model="data.item.talent_salary"
              v-bind="money"
              class="form-control"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>
        <template #cell(additional_cost)="data">
          <ValidationProvider
            #default="{ errors }"
            name="Biaya Lainnya"
            rules="integer"
          >
            <money
              v-model="data.item.additional_cost"
              v-bind="money"
              class="form-control"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>
        <template #cell(admin_fee)="data">
          <VSelect
            label="description"
            :reduce="option => option"
            :options="listAdminFee.filter((el) => el.skill_id === data.item.role_id)"
            placeholder="Biaya Admin"
            taggable
            :clearable="false"
            class="custom-style"
            @input="setAdminFee(data.index)"
            @option:selected="setIndexAdminFee"
          >
            <template #selected-option="{ admin_fee, description }">
              <span>{{ IDR(admin_fee || description) }}</span>
            </template>
          </VSelect>
        </template>
      </BTable>
    </div>
    <ConfirmationBack />
    <ConfirmationSubmit
      :loading-submit="loadingSubmit"
      @confirm="confirmSubmit"
    />
    <AddTalent
      :talent-exist="listTalentexist"
      :list-talent="listTalentFromFetch"
      :partner-name="partnerName"
      @update="updateTalentList"
    />
    <Information
      :data="informationData"
      @close="closeModalInfo()"
    />
  </BCard>
</template>

<script>
import VSelect from 'vue-select'
import MonthlyPicker from 'vue-monthly-picker'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { required } from '@validations'
import { Money } from 'v-money'
import { LABELMONTH, YEAR_MONTH, LABEL_MONTH_YEAR } from '@/libs/filterDate'
import { tableTalent } from './config'
import ConfirmationBack from './modal/ConfirmationBack.vue'
import ConfirmationSubmit from './modal/ConfirmationSubmit.vue'
import AddTalent from './modal/AddTalent.vue'
import Information from './modal/Information.vue'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VSelect,
    ConfirmationBack,
    ConfirmationSubmit,
    Money,
    MonthlyPicker,
    AddTalent,
    Information,
  },
  data() {
    return {
      IDR,
      fields: tableTalent,
      visible: false,
      loading: false,
      alertError,
      alertSuccess,
      required,
      YEAR_MONTH,

      idPartner: null,
      periode: null,
      loadingSubmit: false,
      listPartners: [],
      listAdminFee: [],
      listTalentFromFetch: [],
      items: [],
      indexSelected: null,
      isTax: false,
      money: {
        thousands: '.',
        prefix: 'Rp ',
        precision: 0,
        masked: false,
      },
      monthlabel: LABELMONTH,
      adminServicefee: 4440,
      partnerName: '',
      informationData: {},
      LABEL_MONTH_YEAR,
      minDate: null,
      filterSkillsExist: [],
      paymentOptions: [
        { text: 'Pembayaran Awal', value: true },
        { text: 'Pembayaran Akhir', value: false },
      ],
      isEarlyPayment: null,
      notes: '',
      hideCosts: null,
      additionalCost: 0,
    }
  },
  computed: {
    calculatedItems() {
      return this.items.map(el => ({
        ...el,
        final_admin_fee: el.admin_fee - (el.admin_fee * (el.discount / 100)),
        total: (el.admin_fee - (el.admin_fee * (el.discount / 100))) + el.talent_salary + el.additional_cost,
      }))
    },
    totalAdminFee() {
      return this.calculatedItems.reduce((a, b) => a + b.final_admin_fee, 0)
    },
    totalTalentSalary() {
      return this.calculatedItems.reduce((a, b) => a + b.talent_salary, 0)
    },
    totalAdditionalCost() {
      return this.calculatedItems.reduce((a, b) => a + b.additional_cost, 0)
    },
    totalNominal() {
      return (this.isTax ? this.totalAdminFee - (this.totalAdminFee * 0.02) : this.totalAdminFee) + this.totalTalentSalary + 4440 + this.totalAdditionalCost
    },
    maxDatePicker() { return this.$moment().add(1, 'month').endOf('month') },
    minDatePicker() { return this.$moment(this.minDate).startOf('month') },
    listTalentexist() {
      return this.items.map(el => el.talent_id)
    },
    filterSkill() {
      return this.filterSkillsExist.join(',')
    },
  },
  mounted() {
    this.getListPartner('')
  },
  methods: {
    async getListPartner(keyword) {
      const params = `&limit=20&status=active,pause&keyword=${keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPartners = data.map(e => ({ ...e, label: `${e.no_partner} - ${e.name}` }))
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPartner: _.debounce((search, it) => {
      it.getListPartner(search)
    }, 500),
    onSearch(search) {
      this.searchPartner(search, this)
    },
    async getListTalent(period) {
      const params = `partner_id=${this.idPartner.id}&period=${period ? YEAR_MONTH(this.periode) : ''}`
      const url = `v1/partner_assignments/invoice_resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data

          if (data) {
            this.listTalentFromFetch = data
            this.items = data.map(el => ({
              ...el,
              admin_fee: 0,
              admin_fee_id: null,
              talent_salary: 0,
              additional_cost: 0,
            }))

            const skills = data.map(el => el.role_id)
            this.filterSkillsExist = [...new Set(skills)]
            this.getListAdminFee()
          } else {
            this.listTalentFromFetch = []
            this.items = []
          }
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListAdminFee() {
      const params = `limit=20&skill=${this.filterSkill}`
      const url = `v1/admin_fees?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listAdminFee = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    handleInput(val) {
      this.items = []
      if (val) {
        this.listTalentFromFetch = []
        this.minDate = val.first_assign_date
        this.partnerName = val.name
        this.getListTalent()
      }
      this.checkAvailability()
    },
    setAdminFee(index) {
      this.indexSelected = index
    },
    setIndexAdminFee(val) {
      if (val.description && !val.id) this.items[this.indexSelected].admin_fee = parseInt(val.description, 10)
      else if (val.description && val.id) {
        this.items[this.indexSelected].admin_fee = parseInt(val.admin_fee, 10)
        this.items[this.indexSelected].admin_fee_id = val.id
      }
    },
    async confirmSubmit() {
      this.loadingSubmit = true

      const data = {
        partner_id: this.idPartner.id,
        invoice_period: YEAR_MONTH(this.periode),
        invoice_details: this.calculatedItems,
        is_tax: this.isTax,
        total_admin_fee: this.totalAdminFee,
        total_talent_salary: this.totalTalentSalary,
        admin_service_fee: this.adminServicefee,
        total_nominal: this.totalNominal,
        is_early_payment: this.isEarlyPayment,
        notes: this.notes,
        hide_costs: this.hideCosts === null ? false : this.hideCosts,
      }

      const url = 'v1/invoices/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push({ name: 'invoices-admin' })
        })
        .catch(error => {
          this.alertError(error)
          this.loadingSubmit = false
        })
    },
    removeTalent(index) {
      this.items.splice(index, 1)
    },
    updateTalentList(payload) {
      const data = payload.map(el => ({
        ...el,
        admin_fee: 0,
        admin_fee_id: null,
        talent_salary: 0,
      }))
      this.items.push(...data)
    },
    async checkAvailability() {
      if (this.idPartner && this.periode) {
        this.getListTalent(true)

        const params = `partner_id=${this.idPartner.id}&period=${YEAR_MONTH(this.periode)}`
        const url = `v1/invoices/check?${params}`
        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data

            if (data) {
              this.informationData = data
              this.$bvModal.show('information')
            }
          })
          .catch(err => {
            this.alertError(err)
            this.loading = false
          })
      }
    },
    closeModalInfo() {
      this.$bvModal.hide('information')
    },
    checkForm() {
      const emptyAdminFee = this.items.filter(item => item.admin_fee === 0)
      const emptySalary = this.items.filter(item => item.talent_salary === 0)

      if (emptyAdminFee.length || emptySalary.length) return true
      return false
    },
  },
}
</script>

<style lang="scss">
.custom-style .vs__dropdown-toggle {
  height: 37.77px;
}
.custom-style .vs__selected-options {
  width: 100px;
}
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 5px;

  &-yellow {
    border: 1px solid #FBA63C;
    background: #FFF2E2;
    color: #FBA63C;
  }
}
</style>
