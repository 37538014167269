<template>
  <BModal
    id="information"
    ref="information"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-1">
        Invoice Sudah Dibayar
      </div>
      <p class="text-black">
        {{ `Invoice partner ${partnerName} periode ${period} sudah berhasil dibayar` }}
      </p>
      <p class="text-black mb-[10px]">
        Invoice kode
      </p>
      <p class="text-black font-bold mb-1">
        {{ `${invoiceCode}` }}
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1 w-100"
          @click="$emit('close')"
        >
          Tutup
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { LABEL_MONTH_YEAR } from '@/libs/filterDate'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    partnerName() {
      return this.data.partner_name
    },
    invoiceCode() {
      return this.data.invoice_code
    },
    period() {
      return LABEL_MONTH_YEAR(this.data.invoice_period)
    },
  },
}
</script>
